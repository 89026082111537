<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">KETERAMPILAN KLINIS JEJARING</p>
          <a v-if="aktivitasLength > 0" class="card-header-icon" @click.stop.prevent="saveObjects()">
            <b-icon icon="save" size="is-small" class="has-text-light" />
          </a>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="aktivitasList"
            :mobile-cards="false"
          >
            
              <b-table-column label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
              <b-table-column label="Aktivitas" v-slot="props">
                <span class="tanggal">{{ props.row.tanggal }}</span>
                <br />
                <template v-if="props.row.perawatan">
                  <router-link :to="{ name: detailNamedURL, params: { id: props.row.id } }">
                    <b-icon
                      class="small-icon"
                      size="is-small"
                      :icon="getRawatIcon(props.row.perawatan.jenis_perawatan)"
                    />
                    {{ props.row.jenis_keterampilan }}
                  </router-link>
                  <br />
                  {{ props.row.perawatan.pasien.nama }} - {{ props.row.perawatan.kode_bill }}<br />
                  <span>{{ props.row.pspd }}<br></span>
                  <small>{{ props.row.sub_departemen }} - {{ props.row.preceptor }}</small>
                </template>
                <template v-else>
                  <router-link :to="{ name: detailNamedURL, params: { id: props.row.id } }">
                    <!-- <b-icon class="small-icon" size="is-small" icon="video" /> -->
                    {{ props.row.jenis_keterampilan }}
                  </router-link>
                  <br />
                  <span>{{ props.row.pspd }}<br></span>
                  <small>{{ props.row.sub_departemen }} - {{ props.row.preceptor }}</small>
                </template>
              </b-table-column>
              <b-table-column label="D.P.T.K" v-slot="props">
                <div v-if="props.row.diagnosis_set.length > 0">
                  <b-field grouped group-multiline>
                    D :
                    <komponen-aktivitas-tag
                      v-for="ak_diag in props.row.diagnosis_set"
                      :key="ak_diag.id"
                      :id="ak_diag.id"
                      :namedUrl="'aktivitas-diagnosis-detail'"
                      :aktivitas="props.row"
                      :aktKomp="ak_diag"
                      :tagColor="getTagColor(props.row.pspd_id, props.row.preceptor_id)"
                    />
                  </b-field>
                  <hr v-if="hasNextAktivitasKomp('D', props.row)" />
                </div>

                <div v-if="props.row.pemeriksaan_set.length > 0">
                  <b-field grouped group-multiline>
                    P :
                    <komponen-aktivitas-tag
                      v-for="ak_pem in props.row.pemeriksaan_set"
                      :key="ak_pem.id"
                      :id="ak_pem.id"
                      :namedUrl="'aktivitas-pemeriksaan-detail'"
                      :aktivitas="props.row"
                      :aktKomp="ak_pem"
                      :tagColor="getTagColor(props.row.pspd_id, props.row.preceptor_id)"
                    />
                  </b-field>
                  <hr v-if="hasNextAktivitasKomp('P', props.row)" />
                </div>

                <div v-if="props.row.tatalaksana_set.length > 0">
                  <b-field grouped group-multiline>
                    T :
                    <komponen-aktivitas-tag
                      v-for="ak_tata in props.row.tatalaksana_set"
                      :key="ak_tata.id"
                      :id="ak_tata.id"
                      :namedUrl="'aktivitas-tatalaksana-detail'"
                      :aktivitas="props.row"
                      :aktKomp="ak_tata"
                      :tagColor="getTagColor(props.row.pspd_id, props.row.preceptor_id)"
                    />
                  </b-field>
                  <hr v-if="hasNextAktivitasKomp('T', props.row)" />
                </div>

                <div v-if="props.row.keterampilan_set.length > 0">
                  <b-field grouped group-multiline>
                    K :
                    <komponen-aktivitas-tag
                      v-for="ak_pem in props.row.keterampilan_set"
                      :key="ak_pem.id"
                      :id="ak_pem.id"
                      :namedUrl="'aktivitas-keterampilan-detail'"
                      :aktivitas="props.row"
                      :aktKomp="ak_pem"
                      :keterampilan="true"
                      :tagColor="getTagColor(props.row.pspd_id, props.row.preceptor_id)"
                    />
                  </b-field>
                </div>
              </b-table-column>
              <b-table-column field="verifikasi" label="Verifikasi" v-slot="props">
                <b-select v-model="payLoadData[props.row.id].verifikasi">
                  <option value="Belum">Belum</option>
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </b-select>
              </b-table-column>
            

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { has } from "lodash";
import { mapState } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import AktivitasList from "../models/aktJejaringList.js";

export default {
  name: "AktivitasList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    KomponenAktivitasTag: () =>
      import("@/apps/aktivitas/components/KomponenAktivitasTag.vue"),
  },
  data() {
    this.aktivitasListMdl = new AktivitasList();
    this.listMdl = this.aktivitasListMdl; // alias untuk digunakan di paginationmixin
    let obv = this.aktivitasListMdl.getObservables();
    obv.detailNamedURL = "aktivitas-detail";
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    aktivitasLength() {
      return this.aktivitasList.length > 0;
    }
  },
  methods: {
    includes(id) {
      if (has(this.aktivitasBelumSet, id)) {
        return this.aktivitasBelumSet[id];
      }
      return null;
    },
    needConfirmation() {
      let vals = Object.values(this.payLoadData);
      let changed = vals.reduce((res, val) => res || val.verifikasi !== "Belum", false);
      return changed;
    },
    filter() {
      this.aktivitasListMdl.applyFilter();
    },
    applyFilter() {
      if (this.needConfirmation()) {
        this.showConfirmationDlg(() => {
          this.saveObjects(this.filter);
        });
      } else {
        this.filter();
      }
    },
    hasNextAktivitasKomp(jenisKomp, row) {
      let kompLenArr = [
        row.diagnosis_set.length,
        row.pemeriksaan_set.length,
        row.tatalaksana_set.length,
        row.keterampilan_set.length
      ];
      const kompOrder = ["D", "P", "T", "K"];
      const startIdx = kompOrder.indexOf(jenisKomp) + 1;
      return kompLenArr.slice(startIdx).reduce((total, num) => total + num) > 0;
    },
    getTagColor(pspd_id, preceptor_id) {
      return this.userId == pspd_id || this.userId == preceptor_id;
    },
    getRawatIcon(jenis) {
      return jenis == "jalan" ? "walking" : "bed";
    },
  },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (has(this.$route.params, "verifikasi")) {
      this.aktivitasListMdl.reset();
      this.filterMap.cari = "";
    }
    this.fetchData();
  }
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.tanggal {
  font-size: 0.8rem !important;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.small-icon {
  vertical-align: middle;
  padding-bottom: 5px;
}
</style>
