import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import APP_CONFIG from "@/apps/core/modules/config.js";
import { has } from "lodash";
import ListModelBase from "@/apps/core/models/listModelBase.js";


const modelName = "aktivitasList";
const modelPath = "/aktivitas/jejaring/";

class AktivitasList extends ListModelBase {
  constructor() {
    let filterMap = { cari: "" }
    super(modelName, modelPath, filterMap);
  }

  initObservables() {
    super.initObservables();
    this.observables.errorList = [];
    this.observables.aktivitasBelumSet = {};
    this.observables.payLoadData = {};
  }

  validateInline(idx) {
    let errorMap = this.observables.errorList[idx];
    if (this.observables.aktivitasList[idx].verifikasi == null) {
      if (has(errorMap, "verifikasi")) {
        errorMap = "";
      }
    }
  }

  getLoadData(data) {
    this.observables.payLoadData = {};
    this.observables.aktivitasBelumSet = {};
    let aktivitasList = super.getLoadData(data);
    this.observables.errorList = [];
    let aktBelumSet = aktivitasList.filter(
      d => d.verifikasi == 'Belum'
    );

    for (let aktivitas of aktBelumSet) {
      this.observables.payLoadData[aktivitas.id] = { verifikasi: aktivitas.verifikasi };
      this.observables.aktivitasBelumSet[aktivitas.id] = { verifikasi: aktivitas.verifikasi };
    }
    return aktivitasList;
  }
  
  getPayload() {
    let payLoadKet = {};
    let aktivitasSet = this.observables.aktivitasList.filter(
      ak => !ak.verified && this.observables.payLoadData[ak.id]);
    for (let akt of aktivitasSet) {
      let ver = this.observables.payLoadData[akt.id];
      let aktKetList = akt.keterampilan_set.filter(ak_ket => ak_ket.verifikasi == "Belum")
      for (let akt of aktKetList) {
        payLoadKet[akt.id] = ver;
      }
    }
    let data = {data_set: payLoadKet};
    return data;
  }

  updateErrorFields(respErrorList) {
    /* digunakan untuk load error dari response */
    this.observables.errorList = respErrorList;
  }

  update(onSaved) {
    let updateURL = `${APP_CONFIG.baseAPIURL}${modelPath}verifikasi/`;
    const data = this.getPayload();
    if (data.data_set == null) return;

    axios.patch(updateURL, data)
      .then((response) => {
        Toast.open("Data berhasil disimpan.");
        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {  // Perlu handle 403 juga
          Toast.open("Data gagal disimpan.");
          this.updateErrorFields(error.response.data);
        }
      });
  }
}

export default AktivitasList;