import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import { clone } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";


class ListModelBase {
  constructor(modelName, urlPath, filterMap) {
    this.modelName = modelName;
    this.filterMap = { cari: null };
    if (filterMap) {
      Object.assign(this.filterMap, filterMap);
    }
    this.apiURL = `${APP_CONFIG.baseAPIURL}${urlPath}`;
    this.initialPagination = {
      prevPage: 0,
      page: 1,
      startRowNum: 1,
      total: 0,
      limit: APP_CONFIG.pagination.limit,
      showPagination: true
    }
    this.initObservables();
  }

  initObservables() {
    this.observables = {
      pagination: clone(this.initialPagination),
      filterMap: this.filterMap,
      filterPrevMap: clone(this.filterMap),
      loading: false,
      modelName: this.modelName
    };
    this.observables[this.modelName] = this.getInitialModel();
  }

  getObservables() {
    return this.observables;
  }

  setApiURL(apiURL) {
    this.apiURL = apiURL;
  }

  getApiURL() {
    return this.apiURL;
  }

  getInitialModel() {
    return [];
  }

  getInitialPagination() {
    return clone(this.initialPagination);
  }

  isLoaded() {
    return !this.observables.loading;
  }

  getLoadData(data) {
    return JSON.parse(JSON.stringify(data));
  }

  load() {
    this.observables.loading = true;
    let params = { page: this.observables.pagination.page };
    let filterObj = JSON.parse(JSON.stringify(this.observables.filterMap));
    for (const [key, val] of Object.entries(filterObj)) {
      if (val) {
        params[key] = val;
      }
    }
    axios.get(this.apiURL, { params: params })
      .then(response => {
        let dataList = this.getLoadData(response.data.data);
        this.observables[this.modelName] = dataList;
        this.updatePagination(response.data.pagination);
      })
      .catch(() => {
        // perlu berikan pesan error toast atau snackbar
        Toast.open("Data gagal dimuat.");
        this.reset();
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  resetFilter() {
    this.observables.filterMap = clone(this.observables.filterPrevMap);
  }

  applyFilter() {
    this.observables.pagination.prevPage = 0;
    this.observables.pagination.page = 1;
    this.observables.filterPrevMap = clone(this.observables.filterMap);
    this.load();
  }

  pageChange(page, onPageChange) {
    this.observables.pagination.page = page;
    Promise.resolve([])
      .then(() => this.load())
      .then(() => {
        if (onPageChange) onPageChange();
      });
  }

  updatePagination(pagination) {
    const total = pagination.total;
    const limit = pagination.limit;
    const page = this.observables.pagination.page
    this.observables.pagination.total = total;
    this.observables.pagination.startRowNum = (page - 1) * limit + 1;
    this.observables.pagination.showPagination = total > limit;
    this.observables.pagination.prevPage = page;
  }

  reset() {
    this.observables[this.modelName] = this.getInitialModel();
    this.observables.pagination = this.getInitialPagination();
  }
}


export default ListModelBase;
