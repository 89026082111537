
import { mapState } from "vuex";


export default {
  computed: {
    ...mapState("core", ["isOnline"])
  },
  watch: {
    isOnline(newValue, oldValue) {
      if (!oldValue && newValue) this.fetchData();
    }
  }
}
